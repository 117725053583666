
$bellefair:'Bellefair', serif;
@mixin background_image($pagina) {
  background-image: url(../img/destacadas/chico/#{$pagina}.jpg);
  @include breakpoint(medium) {
    background-image: url(../img/destacadas/mediano/#{$pagina}.jpg);
  }
  @include breakpoint(large) {
    background-image: url(../img/destacadas/grande/#{$pagina}.jpg);
  }
}
body {
  background-color: $secondary-color;
}

.contenedor {
  @include xy-grid-container();
}

.contenido-principal {
  background-color: $white;
  padding: rem-calc(10);
  @include breakpoint(medium){
    margin-top: rem-calc(-120);
    @include position(relative);
    padding: rem-calc(50, 80);
  }
}

blockquote {
  font-size: rem-calc(20);
  background-color: $secondary-color;
}

.efecto-imagen {
  @include rotate(-15);
  width: 90%;
  @include shadow();
  border: rem-calc(10) solid $white;
  @include breakpoint(small only){
    margin-bottom: rem-calc(70);
  }
}

/**Encabezados**/
.encabezado {
  color: $secondary-color;
  @include text-transform(uppercase);
  font-size: rem-calc(18);
  margin-bottom: rem-calc(50);
  margin-top: rem-calc(40);
  text-align: center;
  width: 100%;

  span {
    color: $primary-color;
    font-size: rem-calc(38);
    display: table;
    margin: 0 auto;
  }
  &:after {
    content: '';
    display: block;
    margin: rem-calc(-10) auto 0 auto;
    background-size: rem-calc(80);
    @include box(rem-calc(80), rem-calc(80));
    background-image: url(../img/casco.png);
    background-repeat: no-repeat;
  }
}

.icono-menu {
  @include hamburger($white, $white, 50px, 32px, 6px, 4);
}

.site-header {
  .contenedor {
    z-index: 1;
    @include position(relative);
  }
  @include position(relative);
  background-repeat: no-repeat;
  height: 100vh;
  min-height: rem-calc(650);
  background-size: cover;

  &.inicio {
    @include background_image(inicio);
  }
  &.nosotros {
    @include background_image(nosotros);
  }
  &.servicios {
    @include background_image(servicios);
  }
  &.galeria {
    @include background_image(galeria);
  }
  &.contacto {
    @include background_image(contacto);
  }

  .header-superior {
    @include xy-grid();
    @include flex-align(center, top);
    @include breakpoint(medium) {
      @include flex-align(justify, top);
    }
    @include padding(2);
    .logo {
      @include xy-cell(6);
      @include breakpoint(medium) {
        @include xy-cell(25%);
      }
    }
  }

  &:after {
      background: linear-gradient(to bottom, rgba(0,0,0,.7)20%, rgba(0,0,0,.3));
      content: '';
      @include position(absolute,0,0,0,0);
      @include box(100%,100%);
  }

  .title-bar {
    background: linear-gradient(to bottom, rgba(0,51,85,.7)20%, rgba(0,51,85,.3));
    @include flex-align(center, middle);
  }

  .title-bar-title {
    margin-left: rem-calc(50);
    @include text-transform(uppercase);
    @include font-bold(700);
    font-size: rem-calc(18);
  }

  .header-slogan {
      @include xy-grid();
      @include flex-align(center, middle);
      height: rem-calc(300);
      .slogan {
        color: $white;
        @include font-bold(900);
        @include text-transform(uppercase);
        text-align: (center);
      }
  }

  .navegaciones {
    @include xy-cell();
    @include breakpoint(medium) {
      @include xy-cell(auto);
    }

    .menu-principal {
      @include breakpoint(small only){
        background: linear-gradient(to bottom, rgba(0,51,85,.7)20%, rgba(0,51,85,.3));
      }
      @include menu-base();
      @include flex-align(right, middle);

        li {
          @include breakpoint(small only){
            width: 100%;
            text-align: center;
          }
          a{
            @include text-transform(uppercase);
            @include font-bold(700);
            font-size: rem-calc(18);
          }
        }
      }
    }
}

.menu-social {
  @include menu-base();
  @include flex-align(center, middle);
  @include breakpoint(medium){
    @include flex-align(right, middle);
  }
  margin-bottom: rem-calc(20);
  a {
    &:before {
      display: inline-block;
      font-family: 'Fontawesome';
      font-size: rem-calc(30);
      color: $white;
      -webkit-font-smoothing: antialised;
      content: '\f08e';
    }
    &[href*="facebook.com"]::before{content:'\f082'}
    &[href*="twitter.com"]::before{content:'\f099'}
    &[href*="instagram.com"]::before{content:'\f16d'}
  }
}


.site-footer {
  background-color:$primary-color;
  .contenedor {
    .contenido-footer {
      background-color: $white;
      @include xy-grid();
      @include padding(2,2,2,2);
      .footer-box {
        @include margin(null, null, 2);
        @include xy-cell();
        @include breakpoint(medium){
          @include xy-cell(50%);
          margin-bottom: 0!important;
        }
        text-align: center;
        h3 {
          font-family: $bellefair;
          color: $primary-color;
          @include text-transform(uppercase);
          font-size: rem-calc(16);
        }
        p{
          font-size: rem-calc(12);
          margin: 0;
        }
      }
    }
  }
  .copyright {
    margin: 0;
    @include padding(.5, null, .5, null);
    text-align: center;
    @include font-bold(900);
    color: $white;
    font-size: rem-calc(16);
  }
}

/**Nosotros**/
.pagina-nosotros {
  @include xy-grid();

  .imagen-nosotros, .contenido-nosotros {
    @include xy-cell();
    @include breakpoint(medium) {
      @include xy-cell(50%);
    }
  }
  p {
    line-height: rem-calc(30);
  }
}

/**Servicios**/
.pagina-servicios {
  @include breakpoint(medium) {
    .contenedor-servicio {
      @include odd(){
        .imagen {
          order:2
        }
        .contendido {
          order:1
        }
      }
    }
  }

  .contenedor-servicio {
    @include xy-grid();
    margin-bottom: rem-calc(20);
    .imagen {
      @include xy-cell();
      @include breakpoint(medium){
        @include xy-cell(1 of 2);
      }
    }
    .contenido {
      @include xy-cell();
      @include padding(1);
      @include breakpoint(medium){
        @include xy-cell(1 of 2);
      }
      h3 {
        font-size: rem-calc(20);
        color: $primary-color;
        @include text-transform(uppercase);
        &:after {
          content: '';
          display: block;
          width: rem-calc(200);
          height: rem-calc(2);
          background-color: $secondary-color;
          margin-top: rem-calc(10);
        }
      }
      p {
        font-size: rem-calc(16);
        margin-top: rem-calc(2);
      }
    }
  }
}

/**Geleria**/
.galeria {
  .contenido {
    @include xy-grid();
    .imagen {
      display: block;
      @include xy-cell();
      margin-bottom: rem-calc(25);
      @include breakpoint(medium){
        @include xy-cell(1 of 3);
          @include shadow();
        img {
          border: rem-calc(3) solid $primary-color;
        }
      }
    }
  }
}

.pagina-contacto {
  @include xy-grid();
  .formulario-contacto {
    @include xy-cell();
    @include breakpoint(medium){
      @include xy-cell(90%);
    }
  }
}

.campo {
  label {
    font-size: rem-calc(16);
    @include text-transform(uppercase);
    @include font-bold();
    color: $primary-color;
  }
  input, textarea, select {
    border: rem-calc(1) solid $primary-color;
    height: rem-calc(30);
  }
  textarea {
    height: rem-calc(100);
  }
  .btn_enviar {
    @include text-transform(uppercase);
    @include font-bold();
    font-size: rem-calc(16);
    width: 100%;
    height: rem-calc(50)
  }
}

.pagina-inicio{
  blockquote {
    @include xy-cell();
    text-align: center;
  }
}

.categoria-iconos {
  @include xy-grid();

  .icono {
    @include xy-cell();
    @include breakpoint(medium){
      @include xy-cell(1 of 3);
    }
    height: rem-calc(250);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: rem-calc(200%);
    @include flex-align(center, bottom);
    @include flex();
    &.construccion {
      background-image: url(../img/construccion.jpg);
    }
    &.electricidad {
      background-image: url(../img/electricidad.jpg);
    }
    &.remodelacion {
      background-image: url(../img/remodelaciones.jpg);
    }
    h3 {
      font-family: $bellefair;
      color: $primary-color;
      font-size: rem-calc(30);
    }
  }
}
